import alasql from "alasql";
import React, { useState, useEffect } from "react";
import Breadcrumbs from "../breadcrumbs";
import PropTypes from "prop-types";
import styled from "styled-components";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PhoneIcon from "@material-ui/icons/Phone";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import HelpIcon from "@material-ui/icons/Help";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ThumbDown from "@material-ui/icons/ThumbDown";
import ThumbUp from "@material-ui/icons/ThumbUp";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import {
  List,
  Datagrid,
  Filter,
  ReferenceManyField,
  Pagination,
  useListContext,
  EditButton,
  ShowButton,
} from "react-admin";
import {
  resourcesTable,
  resourcesTableFields,
  sourcesTable,
  sourcesTableFields,
} from "../../config/data";
import { getFeildWidget } from "../fields";
import { getInputWidget } from "../inputs";
import ResourceShow from "../show";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
      style={{ background: "#fff", borderRadius: 10 }}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

const getExpandWidget = (params: {
  props: object;
  expand: string;
  expand_attributes: any;
}) => {
  let widget: any;
  switch (params.expand) {
    case "show":
      widget = <ResourceShow {...params.props} />;
      break;

    case "list":
      const resource = params.expand_attributes.reference;
      const resourcesTableRecord: any = alasql(
        `select ${resourcesTableFields
          .map((field) => field.split("::")[0])
          .join(",")} from ${resourcesTable} where name="${resource}" `
      )[0];
      widget = (
        <ReferenceManyField
          pagination={<Pagination />}
          {...params.expand_attributes}
        >
          {getDataGrid({
            props: params.props,
            resourcesTableRecord,
            resource,
            isExpand: true,
          })}
        </ReferenceManyField>
      );
      break;
  }
  return widget;
};

const ListContainer = styled.div`
  display: inline-grid;
  padding: 20px 40px 40px 40px;
`;

const Spacer = styled.div`
  height:20px;
`;
const ResourceList = (props) => {
  const { resource } = props;

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const resourcesTableRecord = alasql(
    `select ${resourcesTableFields
      .map((field) => field.split("::")[0])
      .join(",")} from ${resourcesTable} where name="${resource}" `
  )[0];

  return (
    <ListContainer>
      <Breadcrumbs />

      <Spacer />
      {/* <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab label="Item One" icon={<PhoneIcon />} {...a11yProps(0)} />
          <Tab label="Item Two" icon={<FavoriteIcon />} {...a11yProps(1)} />
          <Tab label="Item Three" icon={<PersonPinIcon />} {...a11yProps(2)} />
          <Tab label="Item Four" icon={<HelpIcon />} {...a11yProps(3)} />
          <Tab label="Item Five" icon={<ShoppingBasket />} {...a11yProps(4)} />
          <Tab label="Item Six" icon={<ThumbDown />} {...a11yProps(5)} />
          <Tab label="Item Seven" icon={<ThumbUp />} {...a11yProps(6)} />
        </Tabs>
      </AppBar> */}
      <TabPanel value={value} index={0}>
        <List
          {...props}
          filters={<ResourceFilters />}
          //  aside={<Aside />}
        >
          {getDataGrid({ props, resourcesTableRecord, resource })}
        </List>
      </TabPanel>
      <TabPanel value={value} index={1}>
        Item
      </TabPanel>
      <TabPanel value={value} index={2}>
        <List
          {...props}
          filters={<ResourceFilters />}
          //  aside={<Aside />}
        >
          {getDataGrid({ props, resourcesTableRecord, resource })}
        </List>
      </TabPanel>
      <TabPanel value={value} index={3}>
        Item Four
      </TabPanel>
      <TabPanel value={value} index={4}>
        Item Five
      </TabPanel>
      <TabPanel value={value} index={5}>
        Item Six
      </TabPanel>
      <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel>
    </ListContainer>
  );
};

const Aside = () => {
  const { data, ids } = useListContext();
  return (
    <div style={{ width: 200, margin: "1em" }}>
      <Typography variant="h6">Posts stats</Typography>
      <Typography variant="body2"></Typography>
    </div>
  );
};

const getDataGrid = (params: {
  props: any;
  resourcesTableRecord: any;
  resource: string;
  isExpand?: boolean;
}) => {
  const className = params.isExpand ? "expanded_list" : "";
  const filter = params.isExpand ? "in_expand_list=true" : "is_list=true";
  const { expand, expand_attributes } = params.resourcesTableRecord ?? {};

  const query = `select ${sourcesTableFields
    .map((field) => field.split("::")[0])
    .join(",")} from ${sourcesTable} where ${
    sourcesTableFields[0]
  } <> "" and resource="${params.resource}" and ${filter} `;
  const sources = alasql(query);

  return (
    <Datagrid
      // rowClick="edit"
      className={className}
      expand={
        expand !== "" &&
        getExpandWidget({ props: params.props, expand, expand_attributes })
      }
    >
      {sources.map((source: any) => {
        return getFeildWidget({ ...source, ...params.props });
      })}
      <EditButton />
      <ShowButton />
    </Datagrid>
  );
};

const ResourceFilters = (props) => {
  const { resource } = props;
  const query = `select ${sourcesTableFields
    .map((field) => field.split("::")[0])
    .join(",")} from ${sourcesTable} where ${
    sourcesTableFields[0]
  } <> "" and resource="${resource}" and is_filter=true `;
  const filters = alasql(query);

  return (
    <Filter {...props}>
      {filters.map((filter) => getInputWidget({ ...filter }))}
    </Filter>
  );
};

export default ResourceList;
