
import React from "react";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import { MenuItemLink, getResources, useTranslate } from "react-admin";
import isUUID from "is-uuid";
import styled from "styled-components";
function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const BreadcrumbsContainer = styled.div`
  background: #f3f3f3;
  border-radius: 81px;
`;
const AppBreadcrumbs = ({ breadcrumbs }) => {
  const translate = useTranslate();

  return (
    <BreadcrumbsContainer>
      <Breadcrumbs aria-label="breadcrumb">
        {breadcrumbs.map((props: { breadcrumb: any; match }) => {
          let name = props.breadcrumb.props.children.toLowerCase();
          if (isUUID.v4(name)) {
            name = "the_record_replaced_id";
          }
          return (//@ts-ignore
            <MenuItemLink
              color="inherit"
              to={props.match.url}
              primaryText={translate(
                `resources.${name.toLowerCase().split(" ").join("_")}.name`
              )}
            />
          );
        })}
      </Breadcrumbs>
    </BreadcrumbsContainer>
  );
};

export default withBreadcrumbs()(AppBreadcrumbs);
