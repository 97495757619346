import React, { useState, useEffect } from "react";
import {
  TextInput,
  BooleanInput,
  ReferenceInput,
  NullableBooleanInput,
  SelectInput,
  DateInput,
  DateTimeInput,
  ImageInput,
  ImageField,
  FileInput,
  FileField,
  NumberInput,
  PasswordInput,
  AutocompleteInput,
  RadioButtonGroupInput,
  CheckboxGroupInput,
  SelectArrayInput,
  ReferenceArrayInput,
  SearchInput,
} from "react-admin";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import RichTextInput from "ra-input-rich-text";
import { ImagePlaceholder, VerticalSpacer } from "../common";

export const getInputWidget = (props: any) => {
  const { input_widget_attributes, className } = props;

  let widget: any = <TextInput {...props} />;

  switch (props.input_widget) {
    case "text":
      widget = <TextInput {...props} {...input_widget_attributes} />;
      break;

    case "multiline":
      widget = <TextInput {...props} multiline {...input_widget_attributes} />;
      break;
    case "number":
      widget = <NumberInput {...props} {...input_widget_attributes} />;
      break;
    case "autocomplete":
      widget = (
        <ReferenceInput
          {...props}
          reference={input_widget_attributes["reference"]}
          filterToQuery={(q) => ({
            [input_widget_attributes["optionText"]]: { _like: `%${q}%` },
          })}
        >
          <AutocompleteInput
            optionText={input_widget_attributes["optionText"]}
          />
        </ReferenceInput>
      );

      break;

    case "rich_text":
      widget = <RichTextInput {...props} {...input_widget_attributes} />;
      break;
    case "password":
      widget = <PasswordInput {...props} {...input_widget_attributes} />;
      break;

    case "boolean":
      widget = <BooleanInput {...props} {...input_widget_attributes} />;
      break;
    case "nullable_boolean":
      widget = <NullableBooleanInput {...props} {...input_widget_attributes} />;
      break;

    case "date":
      widget = <DateInput {...props} {...input_widget_attributes} />;
      break;

    case "date_time":
      widget = <DateTimeInput {...props} {...input_widget_attributes} />;
      break;
    case "image":
      widget = (
        <ImageInput {...props} {...input_widget_attributes}>
          <ImageField source="src" title="title" />
        </ImageInput>
      );
      break;
    case "typography":
      widget = (
        <Typography
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          {...input_widget_attributes}
        >
          {props["label"]}
        </Typography>
      );
      break;

    case "image_placeholder":
      widget = <ImagePlaceholder {...input_widget_attributes} />;
      break;

    case "divider":
      widget = (
        <Divider
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          {...input_widget_attributes}
        />
      );
      break;
    case "spacer":
      widget = <VerticalSpacer {...input_widget_attributes} />;
      break;

    case "file":
      widget = (
        <FileInput {...props} {...input_widget_attributes}>
          <FileField source="src" title="title" />
        </FileInput>
      );
      break;

    case "reference":
      widget = (
        <ReferenceInput
          {...props}
          reference={input_widget_attributes["reference"]}
        >
          <SelectInput optionText={input_widget_attributes["optionText"]} />
        </ReferenceInput>
      );
      break;

    case "date_time":
      widget = <DateTimeInput {...props} {...input_widget_attributes} />;
      break;
    case "radio_button_group":
      widget = (
        <RadioButtonGroupInput {...props} {...input_widget_attributes} />
      );
      break;

    case "select":
      widget = <SelectInput {...props} {...input_widget_attributes} />;
      break;
    case "select_array":
      widget = <SelectArrayInput {...props} {...input_widget_attributes} />;
      break;
    case "referance_array":
      widget = (
        <ReferenceArrayInput {...props} {...input_widget_attributes}>
          <SelectArrayInput {...input_widget_attributes} />
        </ReferenceArrayInput>
      );
      break;

    case "checkbox_group":
      widget = <CheckboxGroupInput {...props} {...input_widget_attributes} />;
      break;
  }
  return widget;
};
