import alasql from "alasql";
import React, { useState, useEffect } from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  TabbedForm,
  FormTab,
  useTranslate,
} from "react-admin";
import { sourcesTable, sourcesTableFields } from "../../config/data";
import { getInputWidget } from "../inputs";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import Breadcrumbs from "../breadcrumbs";

const ResourceEdit = (props) => {
  const { resource } = props;
  let role = localStorage.getItem("role");
  const translate = useTranslate();

  let tabsRecords = alasql(
    `select distinct group_input as tab  from sources where resource='${resource}' and is_edit=true and role_${role}='write' `
  ).map(({ tab }) => tab);

  return (
    <>
      <Breadcrumbs />
      <Edit {...props} className="Edit">
        <TabbedForm className="TabbedForm">
          {tabsRecords.map((record: string) => {
            const query = `select ${sourcesTableFields
              .map((field) => field.split("::")[0])
              .join(",")} from ${sourcesTable} where ${
              sourcesTableFields[0]
            } <> "" and resource="${resource}" and group_input="${record}" and is_edit=true and role_${role}='write'  `;
            const sources = alasql(query);
            const label = translate(`resources.${resource}.fields.${record}`);
            return (
              <FormTab key={record} label={label}>
                {sources.map((source: { name: string; label: string }) => {
                  return getInputWidget({ ...props, ...source });
                })}
              </FormTab>
            );
          })}
        </TabbedForm>
      </Edit>
    </>
  );
};

export default ResourceEdit;
