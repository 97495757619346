import alasql from "alasql";

export const appTable = "app";
export const appTableFields = ["name", "property"];

export const resourcesTable = "resources";
export const resourcesTableFields = [
  "name",
  "label",
  "expand",
  "expand_attributes::json",
  "enabled",
];

export const sourcesTable = "sources";
export const sourcesTableFields = [
  "key",
  "source",
  "resource",
  // "label",
  "input_widget",
  "show_widget",
  "fullWidth",
  "className",
  "input_widget_attributes::json",
  "show_widget_attributes::json",
  "is_list",
  "is_filter",
  "in_expand_list",
  "group_input",
  "group_show",
  "is_edit",
  "is_show",
  "is_create",
  "role_admin",
  "role_user",
  "role_viewer",
];
export const translationsTable = "translations";
export const translationsTableFields = [
  "level1",
  "level2",
  "level3",
  "level4",
  "level5",
  "ar",
  "en",
];
//https://ttc-airdata.vercel.app/api/base/app8jwRSwwJ1zkaHn/app/name
export const prepearData = async () => {
  const backendUrl = process.env.REACT_APP_API;
  const debug="?_vercel_no_cache=1";

  await alasql.promise([
    `CREATE TABLE IF NOT EXISTS ${appTable}`,
    `CREATE TABLE IF NOT EXISTS ${resourcesTable}`,
    `CREATE TABLE IF NOT EXISTS ${sourcesTable}`,
    `CREATE TABLE IF NOT EXISTS ${translationsTable}`,
  ]);

  let table = appTable;
  let fields = appTableFields;

  await alasql.promise(
    `SELECT * INTO ${table} FROM JSON('${backendUrl}/${table}/${fields.join(
      ","
    )}${debug}')`
  );

  table = resourcesTable;
  fields = resourcesTableFields;

  await alasql.promise(
    `SELECT * INTO ${table} FROM JSON('${backendUrl}/${table}/${fields.join(
      ","
    )}${debug}')`
  );

  table = sourcesTable;
  fields = sourcesTableFields;

  await alasql.promise(
    `SELECT * INTO ${table} FROM JSON('${backendUrl}/${table}/${fields.join(
      ","
    )}${debug}')`
  );

  table = translationsTable;
  fields = translationsTableFields;

  await alasql.promise(
    `SELECT * INTO ${table} FROM JSON('${backendUrl}/${table}/${fields.join(
      ","
    )}${debug}')`
  );
};
