import uuidv1 from "uuid/v1";

//const UPLOAD_SERVER_ENDPOINT = localStorage.getItem('UPLOAD_SERVER_ENDPOINT');
//const DOWNLOAD_SERVER_ENDPOINT = localStorage.getItem('DOWNLOAD_SERVER_ENDPOINT');
// const UPLOAD_SERVER_ENDPOINT = 'https://ttc-ets-integration-api.tatweertransit.com';
// const DOWNLOAD_SERVER_ENDPOINT = 'https://s3.tatweertransit.com/upload';

let UPLOAD_SERVER_ENDPOINT = "";
let DOWNLOAD_SERVER_ENDPOINT = "";
const upload = (file, distination) => {
  var data = new FormData();
  data.append("file", file.rawFile);

  //return fetch(`http://localhost:4000/${distination}/upload`, {
  return fetch(`${UPLOAD_SERVER_ENDPOINT}/${distination}/upload`, {
    method: "POST",
    body: data,
  });
};

function _retrieveNewURL(fileName, cb?) {
  fetch(`${UPLOAD_SERVER_ENDPOINT}/presignedUrl?name=${fileName}`)
    .then((response) => {
      response.text().then((url) => {
        cb(fileName, url);
      });
    })
    .catch((e) => {
      console.error(e);
    });
}

async function retrieveNewURL(fileName, cb?) {
  let response = await fetch(
    `${UPLOAD_SERVER_ENDPOINT}/presignedUrl?name=${fileName}`
  );
  return response.text();
}

async function uploadFile(file, url) {
  return fetch(url, {
    method: "PUT",
    body: file,
  });
}

const getFileExtention = (rawFile) => {
  let ext: string = "";
  switch (rawFile.type) {
    case "application/pdf":
      ext = "pdf";
      break;
    case "image/png":
      ext = "png";
      break;
  }

  ext = ext ? `.${ext}` : "";

  return ext;
};

/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFile = (requestHandler:any, upload:string, download:string) => async (
  type:string,
  resource:string,
  params:any
) => {

  UPLOAD_SERVER_ENDPOINT = upload;
  DOWNLOAD_SERVER_ENDPOINT = download;
  if (type === "CREATE" || type === "UPDATE") {
    let filesToUpload: any = [];

    Object.keys(params.data).map(function (key, index) {
      if (params.data[key] && params.data[key].hasOwnProperty("rawFile")) {

        filesToUpload.push({ source: key, file: params.data[key] });
      }
    });

    let uploadedParams: any = {};
    await asyncForEach(
      filesToUpload,
      async (f: { file: any; source: string }) => {
        let ext = getFileExtention(f.file.rawFile);
        let fileName = `${uuidv1()}${ext}`;
        let url = await retrieveNewURL(fileName);
        let res = await uploadFile(f.file.rawFile, url);
        uploadedParams[f.source] = {
          src: `${DOWNLOAD_SERVER_ENDPOINT}/${fileName}`,
        };
      }
    );

    return requestHandler(type, resource, {
      ...params,
      data: {
        ...params.data,
        ...uploadedParams,
      },
    });
  }
  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};
export async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}
export default addUploadFile;
