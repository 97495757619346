import React from "react";

import styled from "styled-components";


export const VerticalSpacer = styled.div`
min-height:${(props:{size:number})=> props.size? props.size: 30}px;
`


export const ImagePlaceholder = styled.img`

`