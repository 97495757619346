import alasql from "alasql";

export const getMessages = (local:string) => {
  let lang = {};

  alasql("select distinct level1 from translations ").forEach(a => {
    lang[a.level1] = {};
    alasql(
      `select distinct level2 from translations where level1='${a.level1}'`
    ).map(b => {
      lang[a.level1][b.level2] = {};

      alasql(
        `select distinct level3 from translations where level1='${
          a.level1
        }' and level2='${b.level2}'`
      ).map(c => {
        lang[a.level1][b.level2][c.level3] = {};
        alasql(
          `select distinct level4 from translations where  level1='${
            a.level1
          }' and level2='${b.level2}' and level3='${c.level3}'`
        ).map(d => {
          lang[a.level1][b.level2][c.level3][d.level4] = alasql(
            `select  ${local} as t  from translations where level1='${
              a.level1
            }' and level2='${b.level2}' and level3='${c.level3}' and level4='${
              d.level4
            }'`
          )[0].t;
        });

        let level3Length = alasql(
          `select ${local} as t from translations where level1='${a.level1}' and level2='${
            b.level2
          }' and level3='${c.level3}' `
        ).length;
        if (level3Length == 1)
          lang[a.level1][b.level2][c.level3] = alasql(
            `select  ${local} as t from translations where level1='${
              a.level1
            }' and level2='${b.level2}' and level3='${c.level3}' `
          )[0].t;
      });

      let level2Length = alasql(
        `select  ${local} as t from translations where level1='${a.level1}' and level2='${
          b.level2
        }' `
      ).length;
      if (level2Length == 1)
        lang[a.level1][b.level2] = alasql(
          `select  ${local} as t from translations where level1='${a.level1}' and level2='${
            b.level2
          }' `
        )[0].t;
    });
  });
  return lang;
};