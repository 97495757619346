import React, { useState, useEffect } from "react";
import {
  TextField,
  BooleanField,
  ReferenceField,
  ReferenceManyField,
  Pagination,
  Datagrid,
  EditButton,
  ShowButton,
} from "react-admin";
import { Typography } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import styled from "styled-components";
import { ImagePlaceholder, VerticalSpacer } from "../common";
import { sourcesTable, sourcesTableFields } from "../../config/data";
import alasql from "alasql";

export const getFeildWidget = (props: any) => {
  const { show_widget_attributes } = props;
  let widget: any = <TextField {...props} />;
  const { input_widget_attributes } = props;

  switch (props.show_widget) {
    case "text":
      widget = <TextField {...props} />;
      break;

    case "multiline":
      widget = <TextField {...props} />;
      break;

    case "boolean":
      widget = <BooleanField {...props} />;
      break;

    case "reference":
      widget = (
        <ReferenceField
          {...props}
          reference={show_widget_attributes["reference"]}
        >
          <TextField source={show_widget_attributes["source"]} />
        </ReferenceField>
      );
      break;

    case "typography":
      widget = (
        <Typography
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          {...input_widget_attributes}
        >
          {props["label"]}
        </Typography>
      );
      break;

    case "divider":
      widget = (
        <Divider
          style={{ width: "100%", marginTop: 20, marginBottom: 20 }}
          {...input_widget_attributes}
        />
      );
      break;

    case "spacer":
      widget = <VerticalSpacer {...input_widget_attributes} />;
      break;
    case "image_placeholder":
      widget = <ImagePlaceholder {...input_widget_attributes} />;
      break;
    case "reference_many":
      widget = (
        <ReferenceManyField
          reference={show_widget_attributes.reference}
          target={show_widget_attributes.target}
        >
          {getDataGrid({ resource: show_widget_attributes.reference })}
        </ReferenceManyField>
      );
      break;
  }
  return widget;
};

const getDataGrid = (params: {resource: string }) => {
  const className = "";
  const filter = "is_list=true";
  const query = `select ${sourcesTableFields
    .map((field) => field.split("::")[0])
    .join(",")} from ${sourcesTable} where ${
    sourcesTableFields[0]
  } <> "" and resource="${params.resource}" and ${filter} `;
  const sources = alasql(query);

  return (
    <Datagrid
      // rowClick="edit"
      className={className}
    >
      {sources.map((source: any) => {
        return getFeildWidget(source);
      })}
      <EditButton />
      <ShowButton />
    </Datagrid>
  );
};
