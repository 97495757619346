import alasql from "alasql";
import React, { useState, useEffect } from "react";
import {
  Show,
  SimpleShowLayout,
  TextField,
  TabbedShowLayout,
  Tab,
  useTranslate,
} from "react-admin";
import { sourcesTable, sourcesTableFields } from "../../config/data";
import { getFeildWidget } from "../fields";
import Breadcrumbs from "../breadcrumbs";

const ResourceShow = (props) => {
  const { resource } = props;
  const translate = useTranslate();

  let role = localStorage.getItem("role");

  let tabsRecords = alasql(
    `select distinct group_input as tab  from sources where resource='${resource}' and is_show=true and role_${role}<>'none' `
  ).map(({ tab }) => tab);

  return (
    <>
      <Breadcrumbs />
      <Show {...props}>
        <TabbedShowLayout>
          {tabsRecords.map((record: string) => {
            const query = `select ${sourcesTableFields
              .map((field) => field.split("::")[0])
              .join(",")} from ${sourcesTable} where ${
              sourcesTableFields[0]
            } <> "" and resource="${resource}" and group_show="${record}"  and is_show=true  and role_${role}<>'none'  `;
            const sources = alasql(query);
            const label = translate(`resources.${resource}.fields.${record}`);
            return (
              <Tab key={record} label={label} className="tab">
                {sources.map((source: { name: string; label: string }) => {
                  return getFeildWidget({ ...props, ...source });
                })}
              </Tab>
            );
          })}
        </TabbedShowLayout>
      </Show>
    </>
  );
};

export default ResourceShow;
