import React, { useState, useEffect } from "react";
import hasuraDataProvider from "ra-data-hasura";
import CircularProgress from "@material-ui/core/CircularProgress";
import styled from "styled-components";
import { Admin, fetchUtils, Resource } from "react-admin";
import { Helmet } from "react-helmet";
import { createMuiTheme } from "@material-ui/core/styles";
import authProvider from "./authProvider";
import polyglotI18nProvider from "ra-i18n-polyglot";
import Dashboard from "./pages/dashboard";
import {
  appTable,
  appTableFields,
  prepearData,
  resourcesTable,
  resourcesTableFields,
  sourcesTable,
  sourcesTableFields,
} from "./config/data";
import alasql from "alasql";
import ResourceEdit from "./components/edit";
import ResourceList from "./components/list";
import ResourceCreate from "./components/create";
import ResourceShow from "./components/show";
import LoginPage from "./pages/login";
import { RTL } from "./RTL";
import { getMessages } from "./utils/lang";
import addUploadFile from "./utils/addUploadFile";

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    prepearData().then((data) => {
      setLoading(false);
    });
  }, []);

  if (loading)
    return (
      <LoadingContainer className="LoadingContainer">
        <CircularProgress />
        <LoadingText>Loading ...</LoadingText>
      </LoadingContainer>
    );

  const appTableRecords = alasql(
    `select ${appTableFields.join(",")} from ${appTable} where ${
      appTableFields[0]
    } <> ""`
  );
  const resourcesTableRecords = alasql(
    `select ${resourcesTableFields
      .map((field) => field.split("::")[0])
      .join(",")} from ${resourcesTable} where ${
      resourcesTableFields[0]
    } <> ""  and enabled=true `
  );

  console.log('ss', alasql("select * from resources"))

  console.log("resourcesTableRecords", resourcesTableRecords);

  const sourcesTableTableRecords = alasql(
    `select ${sourcesTableFields
      .map((field) => field.split("::")[0])
      .join(",")} from ${sourcesTable} where ${sourcesTableFields[0]} <> "" `
  );

  const API_URI = appTableRecords.find(
    (record: { name: string }) => record.name === "API_URI"
  ).property;
  const BACKEND_URL = appTableRecords.find(
    (record: { name: string }) => record.name === "BACKEND_URL"
  ).property;

  const APP_TITLE = appTableRecords.find(
    (record: { name: string }) => record.name === "APP_TITLE"
  )?.property;

  const APP_THEME = appTableRecords.find(
    (record: { name: string }) => record.name === "APP_THEME"
  )?.property;

  const APP_LOCAL = appTableRecords.find(
    (record: { name: string }) => record.name === "APP_LOCAL"
  )?.property;

  const APP_DIRECTION = appTableRecords.find(
    (record: { name: string }) => record.name === "APP_DIRECTION"
  )?.property;
  const UPLOAD_SERVER_ENDPOINT = appTableRecords.find(
    (record: { name: string }) => record.name === "UPLOAD_SERVER_ENDPOINT"
  )?.property;
  const DOWNLOAD_SERVER_ENDPOINT = appTableRecords.find(
    (record: { name: string }) => record.name === "DOWNLOAD_SERVER_ENDPOINT"
  )?.property;

  const theme = createMuiTheme({
    direction: APP_DIRECTION,
    palette: {
      type: APP_THEME ? APP_THEME : "light",
    },
  });

  const messages: any = {
    ar: getMessages(APP_LOCAL),
  };
  // let hasuraProvider = hasuraDataProvider(API_URI, headers);
  const httpClient = (url, options) => {
    if (!options.headers) {
      options.headers = new Headers({ Accept: "application/json" });
    }
    // add your own headers here
    const AUTH_TOKEN = localStorage.getItem("token");
    options.headers.set("Authorization", `Bearer ${AUTH_TOKEN}`);
    return fetchUtils.fetchJson(url, options);
  };
  const hasuraProvider = hasuraDataProvider(API_URI, httpClient);
  let uploadCapableFileProvider = addUploadFile(
    hasuraProvider,
    UPLOAD_SERVER_ENDPOINT,
    DOWNLOAD_SERVER_ENDPOINT
  );
  const i18nProvider = polyglotI18nProvider((locale) => messages[locale], "ar");
  authProvider.BACKEND_URL = BACKEND_URL;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{APP_TITLE}</title>
      </Helmet>
      <RTL>
        <Admin
          i18nProvider={i18nProvider}
          loginPage={LoginPage}
          theme={theme}
          locale={APP_LOCAL}
          dashboard={Dashboard}
          dataProvider={uploadCapableFileProvider}
          authProvider={authProvider}
        >
          {resourcesTableRecords.map(
            (resource: { name: string; label: string }) => {
              return (
                <Resource
                  key={resource.name}
                  // options={{ label: resource.label }}
                  name={resource.name}
                  list={ResourceList}
                  edit={ResourceEdit}
                  create={ResourceCreate}
                  show={ResourceShow}
                />
              );
            }
          )}
        </Admin>
      </RTL>
    </>
  );
};

const LoadingContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const LoadingText = styled.h3``;

export default App;
