import alasql from "alasql";
import React, { useState, useEffect } from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  TabbedForm,
  FormTab,
  useTranslate,
} from "react-admin";
import { sourcesTable, sourcesTableFields } from "../../config/data";
import { getInputWidget } from "../inputs";
import Breadcrumbs from "../breadcrumbs";

const ResourceCreate = (props) => {
  const { resource } = props;
  const translate = useTranslate();

  let role = localStorage.getItem("role");

  let tabsRecords = alasql(
    `select distinct group_input as tab  from sources where resource='${resource}' and is_edit=true and role_${role}='write' `
  ).map(({ tab }) => tab);
  console.log("tabsRecords", tabsRecords);
  console.log("role", role);
  return (
    <>
      <Breadcrumbs />
      <Create {...props}>
        <TabbedForm>
          {tabsRecords.map((record: string) => {
            const query = `select ${sourcesTableFields
              .map((field) => field.split("::")[0])
              .join(",")} from ${sourcesTable} where ${
              sourcesTableFields[0]
            } <> "" and resource="${resource}" and group_input="${record}" and  is_edit=true and role_${role}='write' `;
            const sources = alasql(query);
            const label = translate(`resources.${resource}.fields.${record}`);

            return (
              <FormTab key={record} label={label}>
                {sources.map((source: { name: string; label: string }) =>
                  getInputWidget({ ...props, ...source })
                )}
              </FormTab>
            );
          })}
        </TabbedForm>
      </Create>
    </>
  );
};

export default ResourceCreate;
